import { ISOCountryCode } from './country.model';

/**
 * Available corporate legal types
 */
export enum CorporateLegalType {
  /*                    Austria                      */
  /** Austria: Aktiengesellschaft (AG) */
  AUT_AG = `AUT_AG`,
  /** Austria: Einzelunternehmen (e.U.) */
  AUT_EU = `AUT_EU`,
  /** Austria: Gesellschaft nach bürgerlichem Recht (GesbR) */
  AUT_GBR = `AUT_GBR`,
  /** Austria: Gesellschaft mit beschränkter Haftung (GmbH, GesmbH) */
  AUT_GMBH = `AUT_GMBH`,
  /** Austria: GmbH & Co KG */
  AUT_GMBH_CO_KG = `AUT_GMBH_CO_KG`,
  /** Austria: Kommanditgesellschaft (KG) */
  AUT_KG = `AUT_KG`,
  /** Austria: Offene Gesellschaft (OG) */
  AUT_OHG = `AUT_OHG`,
  /** Austria: Stiftung */
  AUT_FOUNDATION = `AUT_FOUNDATION`,

  /*                    Switzerland                      */
  /** Switzerland: Einzelunternehmen / Einzelfirma */
  CHE_EU = `CHE_EU`,
  /** Switzerland: Kollektivgesellschaft / Société en nom collectif (KIG, SNC) */
  CHE_GBR = `CHE_GBR`,
  /** Switzerland: Komanditgesellschaft / Société en Commandite (KmG, SCm, SAc) */
  CHE_KG = `CHE_KG`,
  /** Switzerland: Aktiengesellschaft / Société Anonyme (AG, SA) */
  CHE_AG = `CHE_AG`,
  /** Switzerland: Kommanditaktiengesellschaft (KmAG, SCmA, SAcA) */
  CHE_KGAA = `CHE_KGAA`,
  /** Switzerland: Gesellschaft mit beschränkter Haftung / Société à Responsabilité limitée (GmbH, SARL, Sagl) */
  CHE_GMBH = `CHE_GMBH`,
  /** Switzerland: Stiftung / Fondation */
  CHE_FOUNDATION = `CHE_FOUNDATION`,

  /*                    Germany                      */
  /** Germany: Aktiengesellschaft (AG) */
  DEU_AG = `DEU_AG`,
  /** Germany: AG & Co. KG */
  DEU_AG_CO_KG = `DEU_AG_CO_KG`,
  /** Germany: Einzelunternehmer */
  DEU_EINZELUNTERNEHMER = `DEU_EINZELUNTERNEHMER`,
  /** Germany: Eingetragener Kaufmann / Eingetragene Kauffrau (e.K.) */
  DEU_EK = `DEU_EK`,
  /** Germany: Freier Beruf */
  DEU_FREIER_BERUF = `DEU_FREIER_BERUF`,
  /** Germany: Gesellschaft bürgerlichen Rechts (GbR) */
  DEU_GBR = `DEU_GBR`,
  /** Germany: Gesellschaft mit beschränkter Haftung (GmbH) */
  DEU_GMBH = `DEU_GMBH`,
  /** Germany: GmbH & Co. KG */
  DEU_GMBH_CO_KG = `DEU_GMBH_CO_KG`,
  /** Germany: Körperschaft des öffentlichen Rechts (KdöR) */
  DEU_KDÖR = `DEU_KDÖR`,
  /** Germany: Kommanditgesellschaft (KG) */
  DEU_KG = `DEU_KG`,
  /** Germany: Kommanditgesellschaft auf Aktien (KGaA) */
  DEU_KGAA = `DEU_KGAA`,
  /** Germany: Limited & Co. KG */
  DEU_LIMITED_CO_KG = `DEU_LIMITED_CO_KG`,
  /** Germany: Offene Handelsgesellschaft (OHG) */
  DEU_OHG = `DEU_OHG`,
  /** Germany: Partnergesellschaft */
  DEU_PARTG = `DEU_PARTG`,
  /** Germany: Partnergesellschaft mit beschränkter Berufshaftung */
  DEU_PARTGMBH = `DEU_PARTGMBH`,
  /** Germany: Stiftung & Co. KG */
  DEU_STIFTUNG_CO_KG = `DEU_STIFTUNG_CO_KG`,
  /** Germany: Unternehmergesellschaft (haftungsbeschränkt) (UG) */
  DEU_UG = `DEU_UG`,
  /** Germany: UG (haftungsbeschränkt) & Co. KG */
  DEU_UG_CO_KG = `DEU_UG_CO_KG`,

  /*                    Spain                      */
  /** Spain: Sociedad Regular Colectiva (S.R.C.) */
  ESP_OHG = `ESP_OHG`,
  /** Spain: Sociedad Comanditaria (S.C.) */
  ESP_KG = `ESP_KG`,
  /** Spain: Sociedad de Responsabilidad Limitada (S.R.L.) */
  ESP_GmbH = `ESP_GmbH`,
  /** Spain: Sociedad Anonima (S.A.) */
  ESP_AG = `ESP_AG`,

  /*                    France                      */
  /** France: Société civile (SC) */
  FRA_GBR = `FRA_GBR`,
  /** France: Société civile immobilière (SCI) */
  FRA_SCI = `FRA_SCI`,
  /** France: Société en nom collectif (SNC) */
  FRA_OHG = `FRA_OHG`,
  /** France: Société civile professionelle (SCP) */
  FRA_PARTG = `FRA_PARTG`,
  /** France: Société anonyme (SA) */
  FRA_AG = `FRA_AG`,
  /** France: Société à responsabilité limitée (SARL) */
  FRA_GMBH = `FRA_GMBH`,
  /** France: Société en Commandite par Actions (S.C.A.) */
  FRA_KGAA = `FRA_KGAA`,

  /*                    Great-Britain                      */
  /** Great-Britain: Sole Trader */
  GBR_EU = `GBR_EU`,
  /** Great-Britain: General Partnership */
  GBR_OHG = `GBR_OHG`,
  /** Great-Britain: Limited Liability Partnership (LLC) */
  GBR_LLC = `GBR_LLC`,
  /** Great-Britain: Limited Company */
  GBR_UG = `GBR_UG`,
  /** Great-Britain: Private Limited Company by Shares (Ltd.) */
  GBR_GMBH = `GBR_GMBH`,
  /** Great-Britain: Public Limited Company (PLC) */
  GBR_AG = `GBR_AG`,

  /*                    Italia                      */
  /** Italia: Ditta Individuale */
  ITA_EK = `ITA_EK`,
  /** Italia: Società in nome collettivo (S.n.c.) */
  ITA_OHG = `ITA_OHG`,
  /** Italia: Società in accomandita semplice (S.a.s.) */
  ITA_KG = `ITA_KG`,
  /** Italia: Società a responsabilita limitata (S.r.l.) */
  ITA_GMBH = `ITA_GMBH`,
  /** Italia: Società per Azioni (S.p.A.) */
  ITA_AG = `ITA_AG`,

  /*                    Luxembourg                      */
  /** Luxembourg: Société à responsabilité limitée */
  LUX_SARL = `LUX_SARL`,

  /*                   Netherlands                      */
  /** Netherlands: Besloten vennootschap met beperkte aansprakelijkheid (B.V.) */
  NLD_GMBH = `NLD_GMBH`,
  /** Netherlands: Commanditaire Vennootschap (C.V.) */
  NLD_KG = `NLD_KG`,
  /** Netherlands: Eenmanszaak (Einzelfirma) */
  NLD_EU = `NLD_EU`,
  /** Netherlands: Maatschap */
  NLD_GBR = `NLD_GBR`,
  /** Netherlands: Naamloze Vennootschap (N.V.) */
  NLD_AG = `NLD_AG`,
  /** Netherlands: Vennootschap onder firma (V.O.F.) */
  NLD_OHG = `NLD_OHG`,

  /*                    Portugal                      */
  /** Portugal: Sociedade em nome coletivo (S.N.C.) */
  PRT_OHG = `PRT_OHG`,
  /** Portugal: Sociedade em comandita (S.C.) */
  PRT_KG = `PRT_KG`,
  /** Portugal: Sociedade por quotas de responsabilidade limitada (S.R.L./Ltda.) */
  PRT_GmbH = `PRT_GmbH`,
  /** Portugal: Sociedade anónima (S.A.) */
  PRT_AG = `PRT_AG`,

  /*                    Sweden                      */
  /** Sweden: Handelsbolag (HB) */
  SWE_OHG = `SWE_OHG`,
  /** Sweden: Kommanditbolag (KB) */
  SWE_KG = `SWE_KG`,
  /** Sweden: Aktiebolag (AB) */
  SWE_AG = `SWE_AG`,

  /*                    USA                      */
  /** USA: General Partnership (GP) */
  USA_OHG = `USA_OHG`,
  /** USA: Limited Partnership (LP) */
  USA_KG = `USA_KG`,
  /** USA: Limited Liability Company (LLC) */
  USA_GmbH = `USA_GmbH`,
  /** USA: Corporation (Corp., Inc.) */
  USA_AG = `USA_AG`,

  /*                    Europe                      */
  /** Europäische Gesellschaft / Societas Europaea */
  EU_SE = `EU_SE`,

  /** If none of the options apply */
  OTHER = 'OTHER',
}

export interface ICountryLegalTypes {
  countryCode: ISOCountryCode;
  legalForms: CorporateLegalType[];
}

/** Available countries with their selectable corporate legal types */
export const LEGAL_FORMS: ICountryLegalTypes[] = [
  {
    countryCode: ISOCountryCode.AUT,
    legalForms: [
      CorporateLegalType.OTHER,
      CorporateLegalType.AUT_AG,
      CorporateLegalType.AUT_EU,
      CorporateLegalType.AUT_GBR,
      CorporateLegalType.AUT_GMBH,
      CorporateLegalType.AUT_GMBH_CO_KG,
      CorporateLegalType.AUT_KG,
      CorporateLegalType.AUT_OHG,
      CorporateLegalType.AUT_FOUNDATION,
      CorporateLegalType.EU_SE,
    ],
  },
  {
    countryCode: ISOCountryCode.CHE,
    legalForms: [
      CorporateLegalType.OTHER,
      CorporateLegalType.CHE_EU,
      CorporateLegalType.CHE_GBR,
      CorporateLegalType.CHE_KG,
      CorporateLegalType.CHE_AG,
      CorporateLegalType.CHE_KGAA,
      CorporateLegalType.CHE_GMBH,
      CorporateLegalType.CHE_FOUNDATION,
      CorporateLegalType.EU_SE,
    ],
  },
  {
    countryCode: ISOCountryCode.DEU,
    legalForms: [
      CorporateLegalType.OTHER,
      CorporateLegalType.DEU_AG,
      CorporateLegalType.DEU_AG_CO_KG,
      CorporateLegalType.DEU_EINZELUNTERNEHMER,
      CorporateLegalType.DEU_EK,
      CorporateLegalType.DEU_FREIER_BERUF,
      CorporateLegalType.DEU_GBR,
      CorporateLegalType.DEU_GMBH,
      CorporateLegalType.DEU_GMBH_CO_KG,
      CorporateLegalType.DEU_KDÖR,
      CorporateLegalType.DEU_KG,
      CorporateLegalType.DEU_KGAA,
      CorporateLegalType.DEU_LIMITED_CO_KG,
      CorporateLegalType.DEU_OHG,
      CorporateLegalType.DEU_PARTG,
      CorporateLegalType.DEU_PARTGMBH,
      CorporateLegalType.DEU_STIFTUNG_CO_KG,
      CorporateLegalType.DEU_UG,
      CorporateLegalType.DEU_UG_CO_KG,
      CorporateLegalType.EU_SE,
    ],
  },
  {
    countryCode: ISOCountryCode.ESP,
    legalForms: [
      CorporateLegalType.OTHER,
      CorporateLegalType.ESP_OHG,
      CorporateLegalType.ESP_KG,
      CorporateLegalType.ESP_GmbH,
      CorporateLegalType.ESP_AG,
      CorporateLegalType.EU_SE,
    ],
  },
  {
    countryCode: ISOCountryCode.FRA,
    legalForms: [
      CorporateLegalType.OTHER,
      CorporateLegalType.FRA_GBR,
      CorporateLegalType.FRA_SCI,
      CorporateLegalType.FRA_OHG,
      CorporateLegalType.FRA_PARTG,
      CorporateLegalType.FRA_AG,
      CorporateLegalType.FRA_GMBH,
      CorporateLegalType.FRA_KGAA,
      CorporateLegalType.EU_SE,
    ],
  },
  {
    countryCode: ISOCountryCode.GBR,
    legalForms: [
      CorporateLegalType.OTHER,
      CorporateLegalType.GBR_EU,
      CorporateLegalType.GBR_OHG,
      CorporateLegalType.GBR_LLC,
      CorporateLegalType.GBR_UG,
      CorporateLegalType.GBR_GMBH,
      CorporateLegalType.GBR_AG,
    ],
  },
  {
    countryCode: ISOCountryCode.ITA,
    legalForms: [
      CorporateLegalType.OTHER,
      CorporateLegalType.ITA_EK,
      CorporateLegalType.ITA_OHG,
      CorporateLegalType.ITA_KG,
      CorporateLegalType.ITA_GMBH,
      CorporateLegalType.ITA_AG,
    ],
  },
  {
    countryCode: ISOCountryCode.LUX,
    legalForms: [CorporateLegalType.OTHER, CorporateLegalType.LUX_SARL, CorporateLegalType.EU_SE],
  },
  {
    countryCode: ISOCountryCode.NLD,
    legalForms: [
      CorporateLegalType.OTHER,
      CorporateLegalType.NLD_GMBH,
      CorporateLegalType.NLD_KG,
      CorporateLegalType.NLD_EU,
      CorporateLegalType.NLD_GBR,
      CorporateLegalType.NLD_AG,
      CorporateLegalType.NLD_OHG,
      CorporateLegalType.EU_SE,
    ],
  },
  {
    countryCode: ISOCountryCode.PRT,
    legalForms: [
      CorporateLegalType.OTHER,
      CorporateLegalType.PRT_OHG,
      CorporateLegalType.PRT_KG,
      CorporateLegalType.PRT_GmbH,
      CorporateLegalType.PRT_AG,
      CorporateLegalType.EU_SE,
    ],
  },
  {
    countryCode: ISOCountryCode.SWE,
    legalForms: [
      CorporateLegalType.OTHER,
      CorporateLegalType.SWE_OHG,
      CorporateLegalType.SWE_KG,
      CorporateLegalType.SWE_AG,
      CorporateLegalType.EU_SE,
    ],
  },
  {
    countryCode: ISOCountryCode.USA,
    legalForms: [
      CorporateLegalType.OTHER,
      CorporateLegalType.USA_OHG,
      CorporateLegalType.USA_KG,
      CorporateLegalType.USA_GmbH,
      CorporateLegalType.USA_AG,
      CorporateLegalType.EU_SE,
    ],
  },
];
