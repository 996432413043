/**
 * Available order statuses
 * The order of the values entered here determines the sorting of the OrderFiles
 * by state in the order-list.component.ts. If the values change, they must be
 * entered in the correct order.
 */
export enum OrderStatus {
  /** The order is not completed yet */
  INCOMPLETE = 'INCOMPLETE',

  /** The order has been submitted and is awaiting review */
  SUBMITTED_FOR_REVIEW = 'SUBMITTED_FOR_REVIEW',
  /** The order is in automated review */
  IN_AUTOMATED_REVIEW = 'IN_AUTOMATED_REVIEW',
  /** The order is in manual review */
  IN_MANUAL_REVIEW = 'IN_MANUAL_REVIEW',

  /** Review could not be completed due to missing documents */
  ADDITIONAL_DOCUMENTS_REQUIRED = 'ADDITIONAL_DOCUMENTS_REQUIRED',

  /** The review is completed and the results are provided */
  RESULTS_PROVIDED = 'RESULTS_PROVIDED',
}
