import { Injectable } from '@angular/core';

import { CorporateLegalType, ISOCountryCode, LEGAL_FORMS } from '@models';

@Injectable({
  providedIn: 'root',
})
export class LegalTypeService {
  /**
   * Returns all available legal types of the given country
   * @param country ISO code of the country
   */
  public static getLegalTypesInCountry(country: ISOCountryCode): CorporateLegalType[] {
    // Find matching legal forms, alternatively offer the fallback 'Other'
    return LEGAL_FORMS.find((legalForm) => legalForm.countryCode === country)?.legalForms || [CorporateLegalType.OTHER];
  }
}
