import { RouteUrl } from './route.url';

/** Base public url */
const publicUrl = ['public'];

/**
 * All public urls
 */
export const PUBLIC_URLS = {
  authentication: new RouteUrl([...publicUrl, 'authentication']),
  page404: new RouteUrl([...publicUrl, '404']),
};
