import { IEnvironmentConfig } from './environment.interface';

export const environment: IEnvironmentConfig = {
  version: '3.0.0' + '-dev', // todo: patch automatically in future
  env: 'dev',
  production: false,
  flattenTranslations: false,
  redirectUrl: 'https://dev.kyc.lgas.it/',
  loginUrl: 'https://dev.iam.lgas.it/auth/realms/development/protocol/openid-connect/auth',
  backendUrl: 'https://dev.kyc-app-bff.lgas.it/',
  sso: {
    clientId: 'kyc-app-bff',
    scope: 'openid',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDzcJ9XZWEH6ZE8HkRoSk5vF-EI6WdUgnY',
    authDomain: 'kyc-app-5ca3a.firebaseapp.com',
    projectId: 'kyc-app-5ca3a',
    storageBucket: 'kyc-app-5ca3a.appspot.com',
    messagingSenderId: '11879524470',
    appId: '1:11879524470:web:9161b8804b49c179759757',
    measurementId: 'G-M7E6E9C3YE',
    vapidKey: 'BKOSCZLQFhJXLCZ0DDVUbZYTgrfqn4oizwiSDOEV-uVrwWq51ZMCHERqW7JAY_DLKm3cgBXqfEW4ER-e0tJEdiU',
  },
};
