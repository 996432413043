import { DateTime } from 'luxon';

/**
 * Meta information for data
 */
export interface IMetaModel {
  /** Creation timestamp */
  createdAt?: string;
  /** Timestamp when the data was last updated */
  updatedAt?: string;
}

/**
 * Meta information for data
 */
export class MetaModel implements IMetaModel {
  /** Creation timestamp */
  public createdAt?: string;
  /** Timestamp when the data was last updated */
  public updatedAt?: string;

  constructor() {
    this.createdAt = DateTime.local().toISO();
    this.updatedAt = DateTime.local().toISO();
  }
}
