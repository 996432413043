/**
 * Politically exposed person
 */
export interface IPoliticallyExposedPerson {
  /** First name of the politically exposed person */
  firstName: string;
  /** Last name of the politically exposed person */
  lastName: string;
  /** Political function */
  function: PoliticalFunction;
  /** Id of the related actor */
  relatedToActorId: string;
}

/**
 * Available political functions
 */
export enum PoliticalFunction {
  CHANCELLOR = 'CHANCELLOR',
  // Other political functions will be added
}
