/**
 * Available results
 */
export enum Result {
  // Incomplete order statuses
  /** Additional documents are required and must be uploaded */
  ADDITIONAL_DOCUMENTS_REQUIRED = 'ADDITIONAL_DOCUMENTS_REQUIRED',

  // Final statuses
  /** Order and background checks are not ok */
  NOT_OK = 'NOT_OK',
  /** Order is complete, but background checks were completed with warnings */
  WARNING = 'WARNING',
  /** Order and background checks are ok */
  OK = 'OK',
}
